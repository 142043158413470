import React from 'react';

export default function About() {
  // style="background: linear-gradient(90deg, #667eea 0%, #764ba2 100%)
  return (
    <>
      <div className="sm:py-20 pt-32 bg-palette-green-med justify-center">
        <div className="container mx-auto px-6">
          <div>
            <h2 className="text-4xl font-bold mb-2 text-palette-light">
              About
            </h2>
            {/* <h3 className="text-2xl mb-8 text-palette-light"> */}
            <h3 className="text-2xl font-semibold text-palette-light">
              “The stock market is a device to transfer money from the impatient
              to the patient.”
            </h3>
            <h3 className="text-xl text-palette-light ml-8">- Warren Buffet</h3>
          </div>
        </div>
      </div>

      <section className="mx-auto px-10 p-10">
        <div className="container max-w-5xl mx-auto m-8 text-gray-600 md:px-16">
          <div className="mb-4">
            I don&apos;t think investing should be complicated. Invest in good
            companies. Period.
          </div>
          <h3 className="text-2xl font-semibold mb-1">Why I created this</h3>
          <div className="mb-4">
            How do you find good companies to invest in? Financial statements
            provide insight to a company&apos;s performance, but if you&apos;ve
            ever looked through one, you know it can be tedious to go through
            all the numbers. I created this tool to make it easier to get just
            the information you need and present it in an easy to use format.
          </div>
          <div className="mb-2">
            This is a work in progress, and I&apos;m constantly trying to
            improve it so I appreciate any feedback. I really want this to be
            useful. I post about updates on the{' '}
            <a
              className="text-palette-green-dark no-underline hover:text-palette-green-med"
              href="https://blog.grasshopperstocks.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              blog
            </a>
            , so if you have suggestions, feel free to leave a comment there.
          </div>
          <h3 className="text-2xl font-semibold mb-1">Investment philosophy</h3>
          <div className="mb-4">
            I choose to invest in individual stocks not only because I enjoy it,
            but it also allows me to select companies I want to support. I use a
            fundamentals-based approach to investing with an emphasis on long
            term growth. I&apos;d rather sit back and wait for my investments to
            grow instead of worrying about the day to day movement of stock
            prices. I invest in companies with good fundamentals because they
            are more likely to increase in value and less likely to fold. This,
            in addition to a long term mindset, means I don&apos;t worry when
            the market dips.
          </div>
          <h3 className="text-2xl font-semibold mb-1">Investment strategy</h3>
          <div className="mb-4">
            My investment strategy is very much driven by numbers. I use a
            screener to find candidates, or sometimes, I just find a company I
            like, and I use this tool in deciding if it&apos;s a good
            investment. I look at annual data because I focus on the long term.
            I re-evaluate stocks I own every year, and if they no longer fit the
            criteria, I sell them.
          </div>
          <h3 className="text-2xl font-semibold mb-1">A note</h3>
          <div className="mb-4">
            This is not a magic formula for finding the best stocks. It&apos;s
            meant to provide information and act as a guide rather than a set of
            rules. There are many different investing strategies, and the best
            strategy is one that suits your personality, your risk profile, and
            your style of investing. It&apos;s also not a get rich quick type of
            thing, so just invest your money and have patience, young
            grasshopper.
          </div>
          {/* <div className="mb-4 italic">
            I currently use TD Ameritrade and Public to invest in stocks.
            I&apos;m not affiliated with Public, but I honestly think it&apos;s
            a great place to start investing. It has a social media element so
            you can chat with others and see what they&apos;re investing in.
            There&apos;s no commision, and you can purchase fractional shares,
            which means you can buy as little as $10 worth of Amazon or Google
            stock. You can use this{' '}
            <a
              className="text-palette-green-med"
              href="https://share.public.com/ninjanice"
              target="_blank"
              rel="noopener noreferrer"
            >
              link{' '}
            </a>
            to open an account.
          </div> */}
        </div>
      </section>
    </>
  );
}
