/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTip, ItemTitle } from './components/ReportComponents';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const revenueFail = (revenue, revenueYOY) => revenue < 0 || revenueYOY < 0;

export const revenuePassStatus = (revenueData) => {
  let passStatus = true;
  if (revenueData.length) {
    revenueData.forEach((data) => {
      if (revenueFail(data.totalRevenue, data.totalRevenueYOY)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderRevenueItem = (revenueData) => {
  const passStatus = revenuePassStatus(revenueData);

  return <RevenueItem revenueData={revenueData} passStatus={passStatus} />;
};

export const getRevenueData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    totalRevenue: data.revenue,
    totalRevenueYOY: data.revenue_yoy,
  }));

function RevenueItem({ revenueData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [unit, setUnit] = React.useState();

  React.useEffect(() => {
    if (revenueData.length) {
      setUnit(
        getUnit(
          Math.max.apply(
            Math,
            revenueData.map((o) => o.totalRevenue)
          )
        )
      );
    }
  }, [revenueData]);

  const passFailClass = (revenue, revenueYOY) =>
    getPassFailClass(revenueFail(revenue, revenueYOY));

  // Begin chart data
  const yearLabels = revenueData.map((item) => fiscalDateYear(item.fiscalDate));

  const revenueDataset = revenueData.map((item) =>
    formatValue(item.totalRevenue, unit)
  );

  const revenueYOYDataset = revenueData.map((item) => item.totalRevenueYOY);

  const revenueChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: `Revenue (${unit})`,
        type: 'bar',
        data: revenueDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        type: 'line',
        data: revenueDataset,
        fill: false,
        borderColor: chart.color.greyBorder,
        borderWidth: 1.5,
      },
      {
        label: 'revenue yoy',
        data: revenueYOYDataset,
        hidden: true,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex < 1;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          return `Revenue: ${data.datasets[0].data[tooltipItem.index]} ${unit}`;
        },
        afterLabel(tooltipItem, data) {
          const { index } = tooltipItem;
          const growth = data.datasets[2].data[index];
          if (growth) {
            return `YOY Growth: ${data.datasets[2].data[tooltipItem.index]}%`;
          }
          return null;
        },
      },
    },
    scales: chartScales(),
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={revenueData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const revenueTableData = () =>
    revenueData.map((item) => (
      <td
        className={passFailClass(
          item.totalRevenue,
          item.totalRevenueYOY ? item.totalRevenueYOY : null
        )}
        key={`revenue-${item.fiscalDate}`}
      >
        {formatValue(item.totalRevenue, unit)}
      </td>
    ));

  const revenueYOYTableData = () =>
    revenueData.map((item) => (
      <td
        className={passFailClass(
          item.totalRevenue,
          item.totalRevenueYOY ? item.totalRevenueYOY : null
        )}
        key={`revenue-yoy-${item.fiscalDate}`}
      >
        {item.totalRevenueYOY != null ? `${item.totalRevenueYOY}%` : ''}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'YOY growth values are positive which means revenue has been increasing each year. Double digit growth values are a plus.';
    }
    return 'Revenue has decreased. Ideally, it should be increasing every year.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const revenueTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Revenue or sales is the income generated by a company from its business activities."
      importance="It's known as the company's top line, and it's the main reason a company goes into business.
        Stock growth starts with a company making money."
      caution="If net income is increasing significantly faster than revenue, it could mean the
        company is cutting costs. This may not be sustainable in the long term."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Revenue"
      pass={passStatus}
      icon={faMoneyCheckAlt}
      tip={revenueTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={revenueChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName={`Revenue (${unit})`} />
        {revenueTableData()}
      </tr>
      <tr>
        <RowHeader itemName="YOY Growth" />
        {revenueYOYTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

RevenueItem.propTypes = {
  revenueData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      totalRevenue: PropTypes.number,
      totalRevenueYOY: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
