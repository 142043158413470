import React from 'react';
import PropTypes from 'prop-types';
import {
  renderRevenueItem,
  getRevenueData,
  revenuePassStatus,
} from './lineItems/RevenueItem';
import { renderRoeItem, getRoeData, roePassStatus } from './lineItems/RoeItem';
import {
  renderFreeCashFlowItem,
  getFreeCashFlowData,
  freeCashFlowPassStatus,
} from './lineItems/FreeCashFlowItem';
import {
  renderGrossProfitItem,
  getGrossProfitData,
  grossProfitPassStatus,
} from './lineItems/GrossProfitItem';
import { renderSgaItem, getSgaData, sgaPassStatus } from './lineItems/SgaItem';
import {
  renderNetIncomeItem,
  getNetIncomeData,
  netIncomePassStatus,
} from './lineItems/NetIncomeItem';
import {
  renderLiquidityItem,
  getLiquidityData,
  liquidityPassStatus,
} from './lineItems/LiquidityItem';
import {
  renderLiabilitiesItem,
  getLiabilitiesData,
  liabilitiesPassStatus,
} from './lineItems/LiabilitiesItem';
import {
  renderDebtItem,
  getDebtData,
  debtPassStatus,
} from './lineItems/DebtItem';
import { OverviewItem } from './lineItems/components/ReportComponents';

function Overview({ reportData }) {
  const renderItems = () => {
    if (reportData) {
      const revenueData = getRevenueData(reportData);
      const roeData = getRoeData(reportData);
      const freeCashFlowData = getFreeCashFlowData(reportData);
      const grossProfitData = getGrossProfitData(reportData);
      const sgaData = getSgaData(reportData);
      const netIncomeData = getNetIncomeData(reportData);
      const liquidityData = getLiquidityData(reportData);
      const liabilitiesData = getLiabilitiesData(reportData);
      const debtData = getDebtData(reportData);

      return (
        <>
          <OverviewItem pass={revenuePassStatus(revenueData)}>
            {renderRevenueItem(revenueData)}
          </OverviewItem>
          <OverviewItem pass={roePassStatus(roeData)}>
            {renderRoeItem(roeData)}
          </OverviewItem>
          <OverviewItem pass={freeCashFlowPassStatus(freeCashFlowData)}>
            {renderFreeCashFlowItem(freeCashFlowData)}
          </OverviewItem>
          <OverviewItem pass={grossProfitPassStatus(grossProfitData)}>
            {renderGrossProfitItem(grossProfitData)}
          </OverviewItem>
          <OverviewItem pass={sgaPassStatus(sgaData)}>
            {renderSgaItem(sgaData)}
          </OverviewItem>
          <OverviewItem pass={netIncomePassStatus(netIncomeData)}>
            {renderNetIncomeItem(netIncomeData)}
          </OverviewItem>
          <OverviewItem pass={liquidityPassStatus(liquidityData)}>
            {renderLiquidityItem(liquidityData)}
          </OverviewItem>
          <OverviewItem pass={liabilitiesPassStatus(liabilitiesData)}>
            {renderLiabilitiesItem(liabilitiesData)}
          </OverviewItem>
          <OverviewItem pass={debtPassStatus(debtData)}>
            {renderDebtItem(debtData)}
          </OverviewItem>
        </>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-row flex-wrap flex-grow mt-2">
      {renderItems()}
    </div>
  );
}

Overview.propTypes = {
  reportData: PropTypes.bool.isRequired,
};

export default Overview;
