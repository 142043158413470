import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './navBar/NavBar';
import Footer from './footer/Footer';
import Home from './home/Home';
import About from './about/About';
import Report from './reports/Report';
import PrivacyPolicy from './PrivacyPolicy';

export const CompanyContext = React.createContext();

function App() {
  return (
    <div className="flex flex-col h-screen">
      {/* <CompanyContext.Provider> */}
      <Router>
        <NavBar />
        <div id="content" className="flex-1">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route path="/report/:company">
              <Report />
            </Route>
            <Route path="/:company">
              <Report />
            </Route>
          </Switch>
        </div>
      </Router>
      <div className="w-full pin-b">
        <Footer />
      </div>
      {/* </CompanyContext.Provider> */}
    </div>
  );
}

export default App;
