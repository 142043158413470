/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const liquidityFail = (currentRatio) => currentRatio < 1;

export const liquidityPassStatus = (liquidityData) => {
  let passStatus = true;
  if (liquidityData.length) {
    liquidityData.forEach((item) => {
      if (liquidityFail(item.currentRatio)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderLiquidityItem = (liquidityData) => {
  const passStatus = liquidityPassStatus(liquidityData);

  return (
    <LiquidityItem liquidityData={liquidityData} passStatus={passStatus} />
  );
};

export const getLiquidityData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    currentAssets: data.current_assets,
    currentLiabilities: data.current_liabilities,
    currentRatio: data.current_ratio,
  }));
function LiquidityItem({ liquidityData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [currentAssetsUnit, setCurrentAssetsUnit] = React.useState();
  const [currentLiabilitiesUnit, setCurrentLiabilitiesUnit] = React.useState();

  React.useEffect(() => {
    if (liquidityData.length) {
      setCurrentAssetsUnit(
        getUnit(
          Math.max.apply(
            Math,
            liquidityData.map((o) => o.currentAssets)
          )
        )
      );
      setCurrentLiabilitiesUnit(
        getUnit(
          Math.max.apply(
            Math,
            liquidityData.map((o) => o.currentLiabilities)
          )
        )
      );
    }
  }, [liquidityData]);

  const passFailClass = (currentRatio) =>
    getPassFailClass(liquidityFail(currentRatio));

  // Begin chart data
  const yearLabels = liquidityData.map((item) =>
    fiscalDateYear(item.fiscalDate)
  );

  const currentAssetsDataset = liquidityData.map((item) =>
    formatValue(item.currentAssets, currentAssetsUnit)
  );

  const currentLiabilitiesDataset = liquidityData.map(
    (item) => formatValue(item.currentLiabilities, currentAssetsUnit) // use same units for chart
  );

  const currentRatioDataset = liquidityData.map((item) => item.currentRatio);

  const currentAssetsChartLabel = `Current Assets (${currentAssetsUnit})`;
  const currentLiabilitiesChartLabel = `Current Liabilities (${currentAssetsUnit})`;

  const currentRatioChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: currentAssetsChartLabel,
        data: currentAssetsDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: currentLiabilitiesChartLabel,
        data: currentLiabilitiesDataset,
        backgroundColor: chart.color.orange,
        borderColor: chart.color.orangeBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'Current Ratio',
        hidden: true,
        data: currentRatioDataset,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 2;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const { datasetIndex, index } = tooltipItem;
          const label = data.datasets[datasetIndex].label || '';
          const currentAssets = data.datasets[0].data[index];
          const currentLiabilitiesData = data.datasets[1].data[index];
          switch (label) {
            case currentAssetsChartLabel:
              return `Current Assets: ${currentAssets} ${currentAssetsUnit}`;
            case currentLiabilitiesChartLabel:
              return `Current Liabilities: ${currentLiabilitiesData} ${currentAssetsUnit}`;
            default:
              break;
          }
          return null;
        },
        afterLabel(tooltipItem, data) {
          return `Current Ratio: ${data.datasets[2].data[tooltipItem.index]}`;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       // scaleLabel: {
    //       //   display: true,
    //       //   labelString: `Assets / Liabilities (${unit})`
    //       // }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={liquidityData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const currentAssetsData = () =>
    liquidityData.map((item) => (
      <td
        className={passFailClass(item.currentRatio)}
        key={`current-assets-${item.fiscalDate}`}
      >
        {formatValue(item.currentAssets, currentAssetsUnit)}
      </td>
    ));

  const currentLiabilitiesData = () =>
    liquidityData.map((item) => (
      <td
        className={passFailClass(item.currentRatio)}
        key={`current-liabilities-${item.fiscalDate}`}
      >
        {formatValue(item.currentLiabilities, currentLiabilitiesUnit)}
      </td>
    ));

  const currentRatioData = () =>
    liquidityData.map((item) => (
      <td
        className={passFailClass(item.currentRatio)}
        key={`current-ratio-${item.fiscalDate}`}
      >
        {item.currentRatio}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'Current ratio is consistently greater than 1, which means the company has enough capital on hand to pay for its short term obligations.';
    }
    return 'Current ratio has dropped below 1. Ideally, this should consistently be above 1.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const liquidityTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="It's a measure of the current assets against the current liabilities of a company. It
      indicates the company's abilities to meet its obligations, aka pay the bills."
      importance="It helps determine a company's financial strength and gives an idea of whether it has too
      much or too little cash on hand."
      caution="The lower the ratio, the more likely it is for a company to struggle paying their bills,
      but a high liquidity ratio can also indicate that the company isn't investing their cash
      efficiently."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Liquidity"
      pass={passStatus}
      icon={faFileInvoiceDollar}
      tip={liquidityTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={currentRatioChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="Current Ratio" />
        {currentRatioData()}
      </tr>
      <tr>
        <RowHeader itemName={`Current Assets (${currentAssetsUnit})`} />
        {currentAssetsData()}
      </tr>
      <tr>
        <RowHeader
          itemName={`Current Liabilities (${currentLiabilitiesUnit})`}
        />
        {currentLiabilitiesData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

LiquidityItem.propTypes = {
  liquidityData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      currentAssets: PropTypes.number,
      currentLiabilities: PropTypes.number,
      currentRatio: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
