/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const leverageCheckFail = (leverageRatio) =>
  leverageRatio > 2 || leverageRatio < 0;

const longTermDebtCheckFail = (longTermDebtToEquity) =>
  longTermDebtToEquity > 50 || longTermDebtToEquity < 0;

export const liabilitiesPassStatus = (liabilitiesData) => {
  let passStatus = true;
  if (liabilitiesData.length) {
    liabilitiesData.forEach((data) => {
      if (
        leverageCheckFail(data.leverageRatio) ||
        longTermDebtCheckFail(data.longTermDebtToEquity)
      ) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderLiabilitiesItem = (liabilitiesData) => {
  const passStatus = liabilitiesPassStatus(liabilitiesData);
  return (
    <LiabilitiesItem
      liabilitiesData={liabilitiesData}
      passStatus={passStatus}
    />
  );
};

export const getLiabilitiesData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    totalLiabilities: data.total_liabilities,
    shareholderEquity: data.equity,
    leverageRatio: data.leverage_ratio,
    longTermDebt: data.long_term_debt,
    longTermDebtToEquity: data.long_term_debt_to_equity,
  }));
function LiabilitiesItem({ liabilitiesData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [liabilitiesUnit, setLiabilitiesUnit] = React.useState();
  const [longTermDebtUnit, setLongTermDebtUnit] = React.useState();
  const [equityUnit, setEquityUnit] = React.useState();

  React.useEffect(() => {
    if (liabilitiesData.length) {
      setLiabilitiesUnit(
        getUnit(
          Math.max.apply(
            Math,
            liabilitiesData.map((o) => o.totalLiabilities)
          )
        )
      );
      setLongTermDebtUnit(
        getUnit(
          Math.max.apply(
            Math,
            liabilitiesData.map((o) => o.longTermDebt)
          )
        )
      );
      setEquityUnit(
        getUnit(
          Math.max.apply(
            Math,
            liabilitiesData.map((o) => o.shareholderEquity)
          )
        )
      );
    }
  }, [liabilitiesData]);

  const leveragePassFailClass = (leverageRatio) =>
    getPassFailClass(leverageCheckFail(leverageRatio));

  const longTermDebtPassFailClass = (leverageRatio) =>
    getPassFailClass(longTermDebtCheckFail(leverageRatio));

  // Begin chart data
  const yearLabels = liabilitiesData.map((item) =>
    fiscalDateYear(item.fiscalDate)
  );

  const longTermDebtDataset = liabilitiesData.map((item) =>
    formatValue(item.longTermDebt, liabilitiesUnit)
  );

  const liabilitiesDataset = liabilitiesData.map((item) =>
    formatValue(item.totalLiabilities - item.longTermDebt, liabilitiesUnit)
  );

  const equityDataset = liabilitiesData.map(
    (item) => formatValue(item.shareholderEquity, liabilitiesUnit) // use same units for chart
  );

  const totalLiabilitiesChartLabel = `Total Liabilities (${liabilitiesUnit})`;
  const longTermDebtChartLabel = `Long Term Debt (${liabilitiesUnit})`;
  const equityChartLabel = `Equity (${liabilitiesUnit})`;

  const leverageChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: longTermDebtChartLabel,
        data: longTermDebtDataset,
        backgroundColor: chart.color.darkOrange,
        borderColor: chart.color.orangeBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
        stack: 1,
      },
      {
        label: totalLiabilitiesChartLabel,
        data: liabilitiesDataset,
        backgroundColor: chart.color.orange,
        borderColor: chart.color.orangeBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
        stack: 1,
      },
      {
        label: equityChartLabel,
        data: equityDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
        stack: 2,
      },
    ],
  });

  const options = {
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const { datasetIndex, index } = tooltipItem;
          const label = data.datasets[datasetIndex].label || '';
          const longTermDebtData = data.datasets[0].data[index];
          const totalLiabilitiesData =
            parseFloat(data.datasets[1].data[index]) +
            parseFloat(longTermDebtData);
          const equityData = data.datasets[2].data[index];
          switch (label) {
            case longTermDebtChartLabel:
              return `Long Term Debt: ${longTermDebtData} ${liabilitiesUnit}`;
            case totalLiabilitiesChartLabel:
              return `Total Liabilities: ${totalLiabilitiesData} ${liabilitiesUnit}`;
            case equityChartLabel:
              return `Equity: ${equityData} ${liabilitiesUnit}`;
            default:
              return null;
          }
        },
      },
    },
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 0;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       stacked: true,
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       // scaleLabel: {
    //       //   display: true,
    //       //   labelString: `Liabilities / Equity (${unit})`
    //       // }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={liabilitiesData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const leverageRatioTableData = () =>
    liabilitiesData.map((item) => (
      <td
        className={leveragePassFailClass(item.leverageRatio)}
        key={`leverage-ratio-${item.fiscalDate}`}
      >
        {item.leverageRatio}
      </td>
    ));

  const longTermDebtToEquityTableData = () =>
    liabilitiesData.map((item) => (
      <td
        className={longTermDebtPassFailClass(item.longTermDebtToEquity)}
        key={`long-term-debt-to-equity-${item.fiscalDate}`}
      >
        {item.longTermDebtToEquity}%
      </td>
    ));

  const liabilitiesTableData = () =>
    liabilitiesData.map((item) => {
      const fail =
        leverageCheckFail(item.leverageRatio) ||
        longTermDebtCheckFail(item.longTermDebtToEquity);
      return (
        <td
          className={getPassFailClass(fail)}
          key={`liabilities-${item.fiscalDate}`}
        >
          {formatValue(item.totalLiabilities, liabilitiesUnit)}
        </td>
      );
    });

  const equityTableData = () =>
    liabilitiesData.map((item) => {
      const fail =
        leverageCheckFail(item.leverageRatio) &&
        longTermDebtCheckFail(item.longTermDebtToEquity);
      return (
        <td
          className={getPassFailClass(fail)}
          key={`liabilties-equity-${item.fiscalDate}`}
        >
          {formatValue(item.shareholderEquity, equityUnit)}
        </td>
      );
    });

  const longTermDebtData = () =>
    liabilitiesData.map((item) => {
      const fail = longTermDebtCheckFail(item.longTermDebtToEquity);
      return (
        <td
          className={getPassFailClass(fail)}
          key={`liabilities-long-term-debt-${item.fiscalDate}`}
        >
          {formatValue(item.longTermDebt, longTermDebtUnit)}
        </td>
      );
    });
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'Some industries have high leverage ratios, but in general, a leverage ratio of less than 2 and a debt to equity of less than 50% is a good sign.';
    }
    return 'Some industries have high leverage ratios, but in general, a leverage ratio greater than 2 and a debt to equity greater than 50% is considered risky.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const liabilitiesTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Financial leverage means you're using money to make money. One measure is to
      divide total liabilities by equity, which we're calling leverage ratio. For example,
      a leverage ratio of 2 means for every dollar of equity, the company has 2 dollars of
      liability. Some investors focus on long term debt, referred to here as long term
      debt to equity."
      importance="Increased leverage can lead to potential profitability, but also potential risk."
      caution="Increasing ROE may be due to increasing debt."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Liabilities "
      pass={passStatus}
      icon={faBalanceScale}
      tip={liabilitiesTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={leverageChartData} options={options} />;

  const longTermDebtTableLabel = longTermDebtUnit
    ? `Long Term Debt (${longTermDebtUnit})`
    : 'Long Term Debt';
  const tableBody = (
    <tbody>
      <tr>
        <th className="w-1/5" />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="Leverage Ratio" />
        {leverageRatioTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Total Liabilities (${liabilitiesUnit})`} />
        {liabilitiesTableData()}
      </tr>
      <tr>
        <RowHeader itemName="Long Term Debt to Equity" />
        {longTermDebtToEquityTableData()}
      </tr>
      <tr>
        <RowHeader itemName={longTermDebtTableLabel} />
        {longTermDebtData()}
      </tr>
      <tr>
        <RowHeader itemName={`Equity (${equityUnit})`} />
        {equityTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

LiabilitiesItem.propTypes = {
  liabilitiesData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      totalLiabilities: PropTypes.number,
      shareholderEquity: PropTypes.number,
      leverageRatio: PropTypes.number,
      longTermDebt: PropTypes.number,
      longTermDebtToEquity: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
