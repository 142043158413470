import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import getReportBySymbol from './reportAPI';
import Notification from '../components/modal/Notification';
import getErrorMessage from '../errors';
import PageMenu from './PageMenu';
import Overview from './Overview';
import Comparison from './Comparison';

function Report() {
  const { company } = useParams();
  const history = useHistory();

  const pages = {
    OVERVIEW: 'Overview',
    COMPARISON: 'Comparison',
  };

  const [reportData, setReportData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedPage, setSelectedPage] = useState(pages.OVERVIEW);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!company) {
      if (typeof company === 'string') {
        setLoading(true);
        getReportBySymbol(company).then((response) => {
          // eslint-disable-next-line no-prototype-builtins
          if (response.hasOwnProperty('errors')) {
            setError(getErrorMessage(response.errors));
          } else {
            const { report } = response;
            setSelectedPage(pages.OVERVIEW);
            if (report.items && report.items.length > 0) {
              setReportData(report.items);
              setCompanyInfo(report.company);
              setError(null);
            }
          }
          setLoading(false);
        });
      }
    }
  }, [company, history]);

  const onClose = () => {
    setError(null);
    history.goBack();
    setReportData(null);
  };

  let content = null;

  let displayedPage = null;

  if (selectedPage === pages.COMPARISON) {
    displayedPage = (
      <Comparison reportData={reportData} company={companyInfo} />
    );
  } else {
    displayedPage = <Overview reportData={reportData} />;
  }

  if (loading) {
    content = (
      <div className="sweet-loading text-center mt-5">
        <ScaleLoader
          size={250}
          color="#57BA98"
          loading
          height={90}
          width={9}
          margin={7}
        />
      </div>
    );
  } else {
    content = (
      <>
        <div className="text-left bg-palette-green-med text-palette-light text-xl font-bold p-4">
          {companyInfo ? `${companyInfo.name} (${companyInfo.symbol})` : null}
        </div>
        <PageMenu pages={pages} onChange={setSelectedPage} />
        {displayedPage}
      </>
    );
  }

  const displayError = !!error;
  return (
    <div className="flex-1 bg-palette-light mt-24 sm:mt-16 md:mt-4 pb-24 md:pb-5">
      {displayError ? (
        <Notification title="Oops" notification={error} onClose={onClose} />
      ) : null}
      {content}
    </div>
  );
}

export default Report;
