/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const sgaFail = (sgaToGross, grossProfit) => sgaToGross > 80 || grossProfit < 0;

export const sgaPassStatus = (sgaData) => {
  let passStatus = true;
  if (sgaData.length) {
    sgaData.forEach((item) => {
      if (sgaFail(item.sgaToGross, item.grossProfit)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderSgaItem = (sgaData) => {
  const passStatus = sgaPassStatus(sgaData);

  return <SgaItem sgaData={sgaData} passStatus={passStatus} />;
};

export const getSgaData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    grossProfit: data.gross_profit,
    sga: data.sga,
    sgaToGross: data.sga_to_gross,
  }));

function SgaItem({ sgaData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [sgaUnit, setSgaUnit] = React.useState();
  const [grossProfitUnit, setGrossProfitUnit] = React.useState();

  React.useEffect(() => {
    if (sgaData.length) {
      const calculatedSgaUnit = getUnit(
        Math.max.apply(
          Math,
          sgaData.map((o) => o.sga)
        )
      );
      const calculatedGrossProfitUnit = getUnit(
        Math.max.apply(
          Math,
          sgaData.map((o) => o.grossProfit)
        )
      );
      setSgaUnit(calculatedSgaUnit || calculatedGrossProfitUnit);
      setGrossProfitUnit(calculatedGrossProfitUnit || calculatedSgaUnit);
    }
  }, [sgaData]);

  const passFailClass = (sgaToGross, gross) =>
    getPassFailClass(sgaFail(sgaToGross, gross));

  // Begin chart data
  const yearLabels = sgaData.map((item) => fiscalDateYear(item.fiscalDate));

  const grossProfitDataset = sgaData.map((item) =>
    formatValue(item.grossProfit, grossProfitUnit)
  );

  const sgaDataset = sgaData.map(
    (item) => formatValue(item.sga, grossProfitUnit) // use same unit on charts
  );

  const sgaToGrossDataset = sgaData.map((item) => item.sgaToGross);

  const sgaChartLabel = `SGA (${grossProfitUnit})`;
  const grossProfitChartLabel = `Gross Profit (${grossProfitUnit})`;

  const sgaChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: sgaChartLabel,
        data: sgaDataset,
        backgroundColor: chart.color.green,
        borderColor: chart.color.greenBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: grossProfitChartLabel,
        data: grossProfitDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'SGA to Gross Profit',
        hidden: true,
        data: sgaToGrossDataset,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 2;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const { datasetIndex, index } = tooltipItem;
          const label = data.datasets[datasetIndex].label || '';
          const tooltipSgaData = data.datasets[0].data[index];
          const tooltipGrossProfitData = data.datasets[1].data[index];
          switch (label) {
            case grossProfitChartLabel:
              return `Gross Profit: ${tooltipGrossProfitData} ${grossProfitUnit}`;
            case sgaChartLabel:
              return `SGA: ${tooltipSgaData} ${grossProfitUnit}`;
            default:
              break;
          }
          return null;
        },
        afterLabel(tooltipItem, data) {
          return `SGA to Gross Profit: ${
            data.datasets[2].data[tooltipItem.index]
          }%`;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       // scaleLabel: {
    //       //   display: true,
    //       //   labelString: `SGA / Gross Profit (${grossProfitUnit})`
    //       // }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={sgaData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const sgaToGrossTableData = () =>
    sgaData.map((item) => (
      <td
        className={passFailClass(item.sgaToGross, item.grossProfit)}
        key={`sga-to-gross-${item.fiscalDate}`}
      >
        {item.sgaToGross}%
      </td>
    ));

  const sgaTableData = () =>
    sgaData.map((item) => (
      <td
        className={passFailClass(item.sgaToGross, item.grossProfit)}
        key={`sga-${item.fiscalDate}`}
      >
        {formatValue(item.sga, sgaUnit)}
      </td>
    ));

  const grossProfitTableData = () =>
    sgaData.map((item) => (
      <td
        className={passFailClass(item.sgaToGross, item.grossProfit)}
        key={`sga-grossProfit-${item.fiscalDate}`}
      >
        {formatValue(item.grossProfit, grossProfitUnit)}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'SGA as a percentage of gross profits is less than 80% which is fairly reasonable.';
    }
    return 'SGA is greater than 80% of Gross Profits which means a company is in a highly competitive industry and may be at a disadvantage.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const profitsTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Selling, General & Administrative expenses are the costs indirectly related to making the product.
      It includes salaries, rent, legal fees, commisions, and the like. It's subtracted from gross profits so SGA 
      to gross profits tells you how much of the gross profits are used for these types of expenses. A high value means
      they're using a lot of their profits for these expenses."
      importance="It has an immense impact on the bottom line. When revenues fall, SGA costs remain and eats into
      the profits. Companies with consistently low SGA expenses are at an advantage."
      caution="SGA costs can vary greatly between industries. Conistently low values are ideal, but sometimes a
      company can have low SGA costs, and high R&D costs or capital expenditures expenses."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="SGA"
      pass={passStatus}
      icon={faFileInvoice}
      tip={profitsTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={sgaChartData} options={options} />;

  const sgaTableLabel = sgaUnit ? `SGA (${sgaUnit})` : 'SGA';
  const tableBody = (
    <tbody>
      <tr>
        <th className="w-1/5" />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="SGA / Gross Profit" />
        {sgaToGrossTableData()}
      </tr>
      <tr>
        <RowHeader itemName={sgaTableLabel} />
        {sgaTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`GrossProfit (${grossProfitUnit})`} />
        {grossProfitTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

SgaItem.propTypes = {
  sgaData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      grossProfit: PropTypes.number,
      sga: PropTypes.number,
      sgaToGross: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
