/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  fiscalDateYear,
  chartProps as chart,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const grossProfitFail = (grossMargin) => grossMargin < 20;

export const grossProfitPassStatus = (grossProfitData) => {
  let passStatus = true;
  if (grossProfitData.length) {
    grossProfitData.forEach((data) => {
      if (grossProfitFail(data.grossMargin)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderGrossProfitItem = (grossProfitData) => {
  const passStatus = grossProfitPassStatus(grossProfitData);
  return (
    <GrossProfitItem
      grossProfitData={grossProfitData}
      passStatus={passStatus}
    />
  );
};

export const getGrossProfitData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    grossProfit: data.gross_profit,
    grossMarginYOY: data.gross_margin_yoy,
    grossMargin: data.gross_margin,
    revenue: data.revenue,
  }));

function GrossProfitItem({ grossProfitData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [grossProfitUnit, setGrossProfitUnit] = React.useState();
  const [revenueUnit, setRevenueUnit] = React.useState();

  React.useEffect(() => {
    if (grossProfitData.length) {
      const calculatedGrossProfitUnit = getUnit(
        Math.max.apply(
          Math,
          grossProfitData.map((o) => o.grossProfit)
        )
      );
      const calculatedRevenueUnit = getUnit(
        Math.max.apply(
          Math,
          grossProfitData.map((o) => o.revenue)
        )
      );
      setRevenueUnit(calculatedRevenueUnit);
      setGrossProfitUnit(calculatedGrossProfitUnit || calculatedRevenueUnit);
    }
  }, [grossProfitData]);

  const passFailClass = (grossMargin) =>
    getPassFailClass(grossProfitFail(grossMargin));

  // Begin chart data
  const yearLabels = grossProfitData.map((item) =>
    fiscalDateYear(item.fiscalDate)
  );

  const revenueDataset = grossProfitData.map((item) =>
    formatValue(item.revenue, grossProfitUnit)
  );

  const grossProfitsDataset = grossProfitData.map((item) =>
    formatValue(item.grossProfit, grossProfitUnit)
  );

  const grossMarginDataset = grossProfitData.map((item) => item.grossMargin);

  const grossProfitChartLabel = `Gross Profit (${grossProfitUnit})`;
  const revenueChartLabel = `Revenue (${grossProfitUnit})`;

  const grossProfitsChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: `Gross Profit (${grossProfitUnit})`,
        data: grossProfitsDataset,
        backgroundColor: chart.color.green,
        borderColor: chart.color.greenBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: `Revenue (${grossProfitUnit})`,
        data: revenueDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'Gross Margin',
        hidden: true,
        data: grossMarginDataset,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 2;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const { datasetIndex, index } = tooltipItem;
          const label = data.datasets[datasetIndex].label || '';
          const tooltipGrossProfitData = data.datasets[0].data[index];
          const revenueData = data.datasets[1].data[index];
          switch (label) {
            case grossProfitChartLabel:
              return `Gross Profit: ${tooltipGrossProfitData} ${grossProfitUnit}`;
            case revenueChartLabel:
              return `Revenue: ${revenueData} ${grossProfitUnit}`; // use same unit on charts
            default:
              break;
          }
          return null;
        },
        afterLabel(tooltipItem, data) {
          return `Gross Margin: ${data.datasets[2].data[tooltipItem.index]}%`;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       scaleLabel: {
    //         display: true,
    //         labelString: `Gross Profit / Revenue (${unit})`
    //       }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={grossProfitData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const grossMarginTableData = () =>
    grossProfitData.map((item) => (
      <td
        className={passFailClass(item.grossMargin)}
        key={`gross-margin-${item.fiscalDate}`}
      >
        {item.grossMargin}%
      </td>
    ));

  const grossProfitTableData = () =>
    grossProfitData.map((item) => (
      <td
        className={passFailClass(item.grossMargin)}
        key={`gross-profit-${item.fiscalDate}`}
      >
        {formatValue(item.grossProfit, grossProfitUnit)}
      </td>
    ));

  const revenueTableData = () =>
    grossProfitData.map((item) => (
      <td
        className={passFailClass(item.grossMargin)}
        key={`gross-profit-revenue-${item.fiscalDate}`}
      >
        {formatValue(item.revenue, revenueUnit)}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'Gross margin is consistently above 20% which means the company can make its products at a reasonable cost.';
    }
    return 'A gross margin of less than 20% usually means the company is in a competitive industry where it may be hard to keep a competitive advantage, but consistency is also key.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const grossProfitsTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Gross profit is the amount left after subtracting the cost to make products
      from the revenue. It's how much of the revenue they're keeping after making the product.
      Gross margin is the percentage of revenue remaining. The higher the value, the more profit
      they're keeping."
      importance="A company that can make products at a low cost is at an advantage."
      caution="Gross margin can vary greatly between different companies, but the value should be consistent
      for each company."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Gross Profits"
      pass={passStatus}
      icon={faCoins}
      tip={grossProfitsTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={grossProfitsChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th className="w-1/5" />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="Gross Margin" />
        {grossMarginTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Gross Profit (${grossProfitUnit})`} />
        {grossProfitTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Revenue (${revenueUnit})`} />
        {revenueTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

GrossProfitItem.propTypes = {
  grossProfitData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      grossProfit: PropTypes.number,
      grossMarginYOY: PropTypes.number,
      grossMargin: PropTypes.number,
      revenue: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
