/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

export default function Notification({ title, notification, onClose }) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
    },
  };

  return (
    <Modal isOpen={true} onRequestClose={onClose} style={customStyles}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-screen">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="modal border-0 rounded-lg shadow-lg relative flex flex-col min-w-full w-1/2 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-3xl font-semibold">{title}</h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onClose()}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-gray-600 text-lg leading-relaxed">
                {notification}
              </p>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button
                className="transition duration-500 ease-in-out bg-green-500 hover:bg-green-500 transform hover:-translate-y-1 hover:scale-110 bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: 'all .15s ease' }}
                onClick={() => onClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </Modal>
  );
}

Notification.propTypes = {
  title: PropTypes.bool.isRequired,
  notification: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
