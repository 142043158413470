/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';

const debtFail = (netIncomeToLongTermDebt, longTermDebt) => {
  if (longTermDebt === 0) {
    return false;
  }
  return netIncomeToLongTermDebt < 0.2;
};

export const debtPassStatus = (debtData) => {
  let passStatus = true;
  if (debtData) {
    debtData.forEach((data) => {
      if (debtFail(data.netIncomeToLongTermDebt, data.longTermDebt)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderDebtItem = (debtData) => {
  const passStatus = debtPassStatus(debtData);

  return <DebtItem debtData={debtData} passStatus={passStatus} />;
};

export const getDebtData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    longTermDebt: data.long_term_debt,
    netIncome: data.net_income,
    netIncomeToLongTermDebt: data.net_income_to_long_term_debt,
  }));

function DebtItem({ debtData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [longTermDebtUnit, setLongTermDebtUnit] = React.useState();
  const [netIncomeUnit, setNetIncomeUnit] = React.useState();

  React.useEffect(() => {
    if (debtData) {
      setLongTermDebtUnit(
        getUnit(
          Math.max.apply(
            Math,
            debtData.map((o) => o.longTermDebt)
          )
        )
      );
      setNetIncomeUnit(
        getUnit(
          Math.max.apply(
            Math,
            debtData.map((o) => o.netIncome)
          )
        )
      );
    }
  }, [debtData]);

  const passFailClass = (netIncomeToLongTermDebt, longTermDebt) =>
    getPassFailClass(debtFail(netIncomeToLongTermDebt, longTermDebt));

  // Begin chart data
  const yearLabels = debtData.map((item) => fiscalDateYear(item.fiscalDate));

  const debtDataset = debtData.map((item) =>
    formatValue(item.longTermDebt, netIncomeUnit)
  );

  const netIncomeDataset = debtData.map(
    (item) => formatValue(item.netIncome, netIncomeUnit) // use same units on chart
  );

  const netIncometoDebtDataset = debtData.map(
    (item) => item.netIncomeToLongTermDebt
  );

  const netIncomeChartLabel = `Net Income (${netIncomeUnit})`;
  const longTermDebtChartLabel = `Long Term Debt (${netIncomeUnit})`;

  const debtChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: netIncomeChartLabel,
        data: netIncomeDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: longTermDebtChartLabel,
        data: debtDataset,
        backgroundColor: chart.color.orange,
        borderColor: chart.color.orangeBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'Net Income to Long Term Debt',
        hidden: true,
        data: netIncometoDebtDataset,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 2;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const netIncomeData = data.datasets[0].data[tooltipItem.index];
          const longTermDebtData = data.datasets[1].data[tooltipItem.index];
          switch (label) {
            case netIncomeChartLabel:
              return `Net Income: ${netIncomeData} ${netIncomeUnit}`;
            case longTermDebtChartLabel:
              return `Long Term Debt: ${longTermDebtData} ${netIncomeUnit}`;
            default:
              return 'n/a';
          }
        },
        afterLabel(tooltipItem, data) {
          const incomeToDebt = data.datasets[2].data[tooltipItem.index];
          return `Net Income to Debt: ${incomeToDebt || '--'}`;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       // scaleLabel: {
    //       //   display: true,
    //       //   labelString: `Net Income /  Debt (${unit})`
    //       // }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={debtData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const longTermDebtData = () =>
    debtData.map((item) => (
      <td
        className={passFailClass(
          item.netIncomeToLongTermDebt,
          item.longTermDebt
        )}
        key={`long-term-debt-${item.fiscalDate}`}
      >
        {formatValue(item.longTermDebt, longTermDebtUnit)}
      </td>
    ));

  const netIncomeData = () =>
    debtData.map((item) => (
      <td
        className={passFailClass(
          item.netIncomeToLongTermDebt,
          item.longTermDebt
        )}
        key={`debt-net-income-${item.fiscalDate}`}
      >
        {formatValue(item.netIncome, netIncomeUnit)}
      </td>
    ));

  const netIncomeToLongTermDebtData = () =>
    debtData.map((item) => (
      <td
        className={passFailClass(
          item.netIncomeToLongTermDebt,
          item.longTermDebt
        )}
        key={`net-income-to-long-term-debt-${item.fiscalDate}`}
      >
        {item.netIncomeToLongTermDebt || '--'}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'Net income to long term debt is consistently greater than 0.2, which means the company has enough earnings to pay off all their long term debt within 5 years. ';
    }
    return 'A company should have enough earnings to pay off all their long term debt within 5 years. This means net income to long term debt should be greater than 0.2.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const debtTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Long term debt is debt that matures past a year. Long term debt to net income is
      a measure of how long it would take a company to pay off debt based on its income."
      importance="Companies can use debt to improve profitability,
    but profitable companies shouldn't need large amounts of debt. More importantly,
    a company should be able to afford their debt."
      caution="Increasing ROE may be due to increasing debt."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Debt"
      pass={passStatus}
      icon={faCreditCard}
      tip={debtTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={debtChartData} options={options} />;

  const longTermDebtTableLabel = longTermDebtUnit
    ? `Long Term Debt (${longTermDebtUnit})`
    : 'Long Term Debt';
  const tableBody = (
    <tbody>
      <tr>
        <th className="w-1/5" />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName={longTermDebtTableLabel} />
        {longTermDebtData()}
      </tr>
      <tr>
        <RowHeader itemName={`Net Income (${netIncomeUnit})`} />
        {netIncomeData()}
      </tr>
      <tr>
        <RowHeader itemName="Net Income to Debt" />
        {netIncomeToLongTermDebtData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

DebtItem.propTypes = {
  debtData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      longTermDebt: PropTypes.number,
      netIncome: PropTypes.number,
      netIncomeToLongTermDebt: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
