import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

function Footer() {
  return (
    <footer className="bg-white">
      <div className="container mx-auto px-6 pt-10 pb-6 mt-16 lg:mt-0">
        <div className="text-right text-palette-green-med text-sm mb-8">
          Select data provided by <a href="https://iexcloud.io">IEX Cloud</a>{' '}
          and{' '}
          <a className="mr-1" href="https://newconstructs.com">
            New Constructs, LLC
          </a>
          <FontAwesomeIcon icon={faCopyright} size="xs" />
          <span className="font-semibold"> All rights reserved.</span>
        </div>
        <div className="flex flex-wrap mb-4">
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h5 className="uppercase mb-6 font-semibold text-palette-green-med text-sm">
              Company
            </h5>
            <ul className="mb-4">
              <li className="mt-2">
                <a
                  href="/about"
                  className="text-palette-green-med hover:underline text-sm"
                >
                  About
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="https://blog.grasshopperstocks.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-palette-green-med hover:underline text-sm"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h5 className="uppercase mb-6 font-semibold text-palette-green-med text-sm">
              Links
            </h5>
            <ul className="mb-4">
              <li className="mt-2">
                <a
                  href="mailto: support@grasshopperstocks.com"
                  className="text-palette-green-med hover:underline text-sm"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h5 className="uppercase mb-6 font-semibold text-palette-green-med text-sm">
              Social
            </h5>
            <ul className="mb-4">
              <li className="mt-2">
                <a
                  href="https://public.com/ninjanice"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-palette-green-med hover:underline text-sm"
                >
                  Public
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 text-center md:text-left">
            <h5 className="uppercase mb-6 font-semibold text-palette-green-med text-sm">
              Legal
            </h5>
            <ul className="mb-4">
              <li className="mt-2">
                <a
                  href="/privacy"
                  className="text-palette-green-med hover:underline text-sm"
                >
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-palette-green-med text-sm italic">Disclaimer:</div>
        <div className="ml-4 text-palette-green-med text-sm italic">
          I am not a financial advisor and I am not promoting this as a way to
          invest. This is a tool to aid in evaluating investment prospects, and
          information here should not be considered investment advice or
          recommendation. The content is intended to be used for informational
          purposes only, and I am not responsible for your investments or
          financial decisions.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
