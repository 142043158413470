/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { getRevenueData, renderRevenueItem } from './lineItems/RevenueItem';
import { getRoeData, renderRoeItem } from './lineItems/RoeItem';
import {
  getFreeCashFlowData,
  renderFreeCashFlowItem,
} from './lineItems/FreeCashFlowItem';
import {
  getGrossProfitData,
  renderGrossProfitItem,
} from './lineItems/GrossProfitItem';
import { getSgaData, renderSgaItem } from './lineItems/SgaItem';
import {
  getNetIncomeData,
  renderNetIncomeItem,
} from './lineItems/NetIncomeItem';
import {
  getLiquidityData,
  renderLiquidityItem,
} from './lineItems/LiquidityItem';
import {
  getLiabilitiesData,
  renderLiabilitiesItem,
} from './lineItems/LiabilitiesItem';
import { getDebtData, renderDebtItem } from './lineItems/DebtItem';
import getReportBySymbol from './reportAPI';
import getErrorMessage from '../errors';
import Notification from '../components/modal/Notification';

export default function Comparison({ reportData, company }) {
  const [selected, setSelected] = useState(company.peers[0]);
  const [error, setError] = useState();
  const [comparisonData, setComparisonData] = useState(null);
  const [comparisonCompany, setComparisonCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReportBySymbol(selected).then((response) => {
      if (response.hasOwnProperty('errors')) {
        setError(getErrorMessage(response.errors));
      } else {
        const { report } = response;
        if (report.items && report.items.length > 0) {
          setComparisonData(report.items);
          setComparisonCompany(report.company);
          setError(null);
        }
      }
      setLoading(false);
    });
  }, [selected]);

  const companyRevenueData = getRevenueData(reportData);
  const companyRoeData = getRoeData(reportData);
  const companyFreeCashFlowData = getFreeCashFlowData(reportData);
  const companyGrossProfitData = getGrossProfitData(reportData);
  const companySgaData = getSgaData(reportData);
  const companyNetIncomeData = getNetIncomeData(reportData);
  const companyLiquidityData = getLiquidityData(reportData);
  const companyLiabilitiesData = getLiabilitiesData(reportData);
  const companyDebtData = getDebtData(reportData);

  let comparisonItems = null;

  if (comparisonData) {
    const comparisonRevenueData = getRevenueData(comparisonData);
    const comparisonRoeData = getRoeData(comparisonData);
    const comparisonFreeCashFlowData = getFreeCashFlowData(comparisonData);
    const comparisonGrossProfitData = getGrossProfitData(comparisonData);
    const comparisonSgaData = getSgaData(comparisonData);
    const comparisonNetIncomeData = getNetIncomeData(comparisonData);
    const comparisonLiquidityData = getLiquidityData(comparisonData);
    const comparisonLiabilitiesData = getLiabilitiesData(comparisonData);
    const comparisonDebtData = getDebtData(comparisonData);

    comparisonItems = (
      <>
        <div className="mb-8">{renderRevenueItem(comparisonRevenueData)}</div>
        <div className="mb-8">{renderRoeItem(comparisonRoeData)}</div>
        <div className="mb-8">
          {renderFreeCashFlowItem(comparisonFreeCashFlowData)}
        </div>
        <div className="mb-8">
          {renderGrossProfitItem(comparisonGrossProfitData)}
        </div>
        <div className="mb-8">{renderSgaItem(comparisonSgaData)}</div>
        <div className="mb-8">
          {renderNetIncomeItem(comparisonNetIncomeData)}
        </div>
        <div className="mb-8">
          {renderLiquidityItem(comparisonLiquidityData)}
        </div>
        <div className="mb-8">
          {renderLiabilitiesItem(comparisonLiabilitiesData)}
        </div>
        <div className="mb-8">{renderDebtItem(comparisonDebtData)}</div>
      </>
    );
  }

  let content = null;
  let comparisonName = null;
  if (loading) {
    content = (
      <div className="sweet-loading text-center mt-5">
        <ScaleLoader
          size={250}
          color="#57BA98"
          loading={true}
          height={90}
          width={9}
          margin={7}
        />
      </div>
    );
  } else {
    content = comparisonItems;
    if (comparisonCompany) {
      comparisonName = `${comparisonCompany.name} (${comparisonCompany.symbol})`;
    }
  }

  const textStateClass = (symbol) => {
    if (symbol === selected) {
      return 'text-palette-green-med text-xl font-bold';
    }
    return 'text-gray-500';
  };

  const peers = company.peers.map((symbol) => (
    <div
      onClick={() => setSelected(symbol)}
      onKeyPress={() => setSelected(symbol)}
      className={`cursor-pointer mb-2 ${textStateClass(symbol)}`}
      role="button"
      tabIndex={0}
    >
      {symbol}
    </div>
  ));

  const onCloseError = () => {
    setError(null);
    setComparisonData(null);
    setComparisonCompany(null);
  };

  const displayError = !!error;
  const peerInfo =
    '<p>Peer group refers to companies that are<br>competitors in the same industry. It can be<br>useful to compare companies against others with<br>similar traits so you\'re not comparing apples<br>to oranges. You may also get a sense of what\'s<br>"normal" for companies in that industry.<p>';
  return (
    <>
      {displayError ? (
        <Notification
          title="Oops"
          notification={error}
          onClose={onCloseError}
        />
      ) : null}
      <div className="flex flex-row flex-wrap flex-grow mt-2">
        <div className="w-full sm:w-1/5 p-3">
          <div className="flex flex-col h-full">
            <div className="h-full bg-white rounded-md shadow-lg p-5">
              <div className="mb-4 text-gray-500 text-xl font-bold">
                Peer Group
                <span className="cursor-pointer ml-1" data-tip={peerInfo}>
                  <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
                </span>
                <ReactTooltip
                  backgroundColor="white"
                  textColor="#a0aec0"
                  border={true}
                  borderColor="#edf2f7"
                  place="top"
                  delayShow={50}
                  html={true}
                />
              </div>
              <div className="mb-8 text-gray-500 text-lg font-semibold">
                {peers}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-2/5 p-3">
          <div className="flex flex-col h-full">
            <div className="h-full bg-white rounded-md shadow-lg p-5">
              <div className="mb-8 text-palette-green-med text-xl font-bold">
                {company ? `${company.name} (${company.symbol})` : null}
              </div>
              <div className="mb-8">
                {renderRevenueItem(companyRevenueData)}
              </div>
              <div className="mb-8">{renderRoeItem(companyRoeData)}</div>
              <div className="mb-8">
                {renderFreeCashFlowItem(companyFreeCashFlowData)}
              </div>
              <div className="mb-8">
                {renderGrossProfitItem(companyGrossProfitData)}
              </div>
              <div className="mb-8">{renderSgaItem(companySgaData)}</div>
              <div className="mb-8">
                {renderNetIncomeItem(companyNetIncomeData)}
              </div>
              <div className="mb-8">
                {renderLiquidityItem(companyLiquidityData)}
              </div>
              <div className="mb-8">
                {renderLiabilitiesItem(companyLiabilitiesData)}
              </div>
              <div className="mb-8">{renderDebtItem(companyDebtData)}</div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-2/5 p-3">
          <div className="flex flex-col h-full">
            <div className="h-full bg-white rounded-md shadow-lg p-5">
              <div className="mb-8 text-palette-green-med text-xl font-bold">
                {comparisonName}
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Comparison.propTypes = {
  reportData: PropTypes.bool.isRequired,
  company: PropTypes.bool.isRequired,
};
