/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getBorderColor } from '../utils';

export function ReportItem({ itemTitle, itemChart, tableBody }) {
  return (
    <div className="p-3">
      {itemTitle}
      <div className="mt-4">{itemChart}</div>
      <table className="w-full table-auto mt-2">{tableBody}</table>
    </div>
  );
}

export function OverviewItem({ pass, children }) {
  return (
    <div className="w-full md:w-1/2 xl:w-1/3 p-3">
      <div
        className={`h-full border-b-4 bg-white ${getBorderColor(
          pass
        )} rounded-md shadow-lg p-5 mb-2`}
      >
        {children}
      </div>
    </div>
  );
}

export function ComparisonItem({ children }) {
  return <div className="w-full sm:w-2/5 p-3">{children}</div>;
}

export function ItemTitle({
  title,
  subtitle,
  pass,
  tip,
  icon,
  setDisplay,
  tipDisplay,
}) {
  const textColor = pass ? 'text-green-600' : 'text-orange-600';
  const tipBg = pass ? 'bg-palette-green-med' : 'bg-orange-500';
  const textHover = pass
    ? 'hover:text-palette-green-med'
    : 'hover:text-orange-500';
  const help = tip ? (
    <span
      className={`${tipBg} tooltip-text text-white p-3 -mt-1 -ml-0 ml-1 rounded opacity-85`}
    >
      {tip}
    </span>
  ) : null;

  return (
    <div className={`${textColor} flex mb-2 ${textHover}`}>
      <div className="text-opacity-75 tooltip cursor-pointer">
        <FontAwesomeIcon
          icon={icon}
          size="3x"
          onClick={() => setDisplay(true)}
        />
        <span className="cursor-default">{tipDisplay ? help : null}</span>
      </div>
      <div
        className="font-bold mt-3 ml-6 cursor-pointer"
        onClick={() => setDisplay(true)}
        onKeyPress={() => setDisplay(true)}
        role="button"
        tabIndex={0}
      >
        {title}
      </div>
      <div className="text-sm text-gray-600 mt-3 ml-2">{subtitle}</div>
    </div>
  );
}

export function ItemTip({
  definition,
  importance,
  guidance,
  caution,
  onClose,
}) {
  return (
    <div>
      <div
        className="text-right font-bold mt-1 mr-1 cursor-pointer mb-1"
        onClick={onClose}
        onKeyPress={onClose}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="text-md font-bold mb-4 ml-1">{guidance}</div>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>What is it</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{definition}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Why it&apos;s important</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{importance}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>What to watch for</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{caution}</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

ReportItem.propTypes = {
  itemTitle: PropTypes.string.isRequired,
  itemChart: PropTypes.elementType.isRequired,
  tableBody: PropTypes.elementType.isRequired,
};

OverviewItem.propTypes = {
  pass: PropTypes.bool.isRequired,
  children: PropTypes.elementType.isRequired,
};

ComparisonItem.propTypes = {
  children: PropTypes.elementType.isRequired,
};

ItemTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  pass: PropTypes.bool.isRequired,
  tip: PropTypes.elementType.isRequired,
  icon: PropTypes.elementType.isRequired,
  setDisplay: PropTypes.bool.isRequired,
  tipDisplay: PropTypes.bool.isRequired,
};

ItemTip.propTypes = {
  definition: PropTypes.string.isRequired,
  importance: PropTypes.string.isRequired,
  guidance: PropTypes.string.isRequired,
  caution: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
