export const getUnit = (value) => {
  const numDigits = value.toString().length;
  if (numDigits > 9) {
    return 'B';
  }
  if (numDigits > 6) {
    return 'M';
  }
  if (numDigits > 3) {
    return 'Th';
  }
  return '';
};

export const formatValue = (value, unit) => {
  switch (unit) {
    case 'B':
      return (value / 1000000000).toFixed(2);
    case 'M':
      return (value / 1000000).toFixed(2);
    case 'Th':
      return (value / 1000).toFixed(2);
    default:
      return value;
  }
};

export const getBorderColor = (pass) => {
  if (pass) {
    return 'border-green-600';
  }
  return 'border-orange-600';
};

export const getPassFailClass = (fail) => {
  let classColor = 'text-green-600';

  if (fail) {
    classColor = 'text-orange-600';
  }
  return `text-center text-xs py-1 ${classColor}`;
};

export const fiscalDateYear = (fiscalDate) => fiscalDate.split('-')[0];

export const chartProps = {
  color: {
    blue: 'rgba(54, 162, 235, 0.2)',
    blueBorder: 'rgba(54, 162, 235, 1)',
    greyBorder: '#718096',
    green: 'rgba(75, 192, 192, 0.2)',
    greenBorder: 'rgb(75, 192, 192)',
    orange: 'rgba(255, 159, 64, 0.2)',
    darkOrange: 'rgba(255, 159, 64, 0.4)',
    orangeBorder: 'rgb(255, 159, 64)',
  },
  bar: {
    borderWidth: 1,
    percentage: 0.6,
  },
  legend: {
    display: true,
    position: 'bottom',
    boxWidth: 18,
    fontSize: 11,
    padding: 5,
  },
};

export const chartScales = () => ({
  xAxes: [
    {
      gridLines: {
        display: false,
      },
    },
  ],
  yAxes: [
    {
      gridLines: {
        color: 'rgba(0, 0, 0, 0.06)',
      },
      ticks: {
        beginAtZero: true,
      },
    },
  ],
});
