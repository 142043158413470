/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  fiscalDateYear,
  chartProps as chart,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const roeFail = (roe, netIncome, equity) => {
  if (equity < 0) {
    return netIncome > equity
  }
  return roe < 10
}

export const roePassStatus = (roeData) => {
  let passStatus = true;
  if (roeData.length) {
    roeData.forEach((item) => {
      const { roe, equity, netIncome } = item 
      if (roeFail(roe, netIncome, equity)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderRoeItem = (roeData) => {
  const passStatus = roePassStatus(roeData);

  return <RoeItem roeData={roeData} passStatus={passStatus} />;
};

export const getRoeData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    roe: data.roe,
    netIncome: data.net_income,
    equity: data.equity,
  }));

function RoeItem({ roeData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [netIncomeUnit, setNetIncomeUnit] = React.useState();
  const [equityUnit, setEquityUnit] = React.useState();

  React.useEffect(() => {
    if (roeData.length) {
      setNetIncomeUnit(getUnit(roeData[0].netIncome));
      setEquityUnit(getUnit(roeData[0].equity));
    }
  }, [roeData]);

  const passFailClass = (roe, netIncome, equity) => getPassFailClass(roeFail(roe, netIncome, equity));

  // Begin chart data
  const yearLabels = roeData.map((item) => fiscalDateYear(item.fiscalDate));

  const roeDataset = roeData.map((item) => item.roe);

  const netIncomeDataset = roeData.map((item) =>
    formatValue(item.netIncome, netIncomeUnit)
  );

  const equityDataset = roeData.map((item) =>
    formatValue(item.equity, equityUnit)
  );

  const roeChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: 'ROE (%)',
        data: roeDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'net income',
        data: netIncomeDataset,
        hidden: true,
      },
      {
        label: 'equity',
        data: equityDataset,
        hidden: true,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex < 1;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          return `ROE: ${data.datasets[0].data[tooltipItem.index]}%`;
        },
        afterLabel(tooltipItem, data) {
          return `Net Income: ${
            data.datasets[1].data[tooltipItem.index]
          } ${netIncomeUnit} \
          \nEquity: ${data.datasets[2].data[tooltipItem.index]} ${equityUnit}`;
        },
      },
    },
    scales: chartScales(),
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={roeData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const roeTableData = () =>
    roeData.map((item) => (
      <td className={passFailClass(item.roe, item.netIncome, item.equity)} key={`roe-${item.fiscalDate}`}>
        {item.roe}%
      </td>
    ));

  const equityTableData = () =>
    roeData.map((item) => (
      <td
        className={passFailClass(item.roe, item.netIncome, item.equity)}
        key={`roe-equity-${item.fiscalDate}`}
      >
        {formatValue(item.equity, equityUnit)}
      </td>
    ));

  const netIncomeTableData = () =>
    roeData.map((item) => (
      <td
        className={passFailClass(item.roe, item.netIncome, item.equity)}
        key={`roe-net-income-${item.fiscalDate}`}
      >
        {formatValue(item.netIncome, netIncomeUnit)}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'An ROE of at least 10% is a sign of a strong company.';
    }
    return 'ROE should be at least 10% and should not be trending down. Net income should also be greater than equity.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const roeTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Return on Equity is the net income divided by shareholders equity.
      It tells you how much the shareholders get for their investment."
      importance="Companies with high ROE and low debt are able to raise money for growth. 
      It means they can invest back into the business without needing more capital."
      caution="ROE can increase if the company is acquiring more debt. If liabilities increase,
      equity decreases, which boosts ROE. Check the Liabilities item and see if the company
      is becoming too leveraged."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="ROE"
      pass={passStatus}
      icon={faHandHoldingUsd}
      tip={roeTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={roeChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="ROE" />
        {roeTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Net Income (${netIncomeUnit})`} />
        {netIncomeTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Equity (${equityUnit})`} />
        {equityTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

RoeItem.propTypes = {
  roeData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      roe: PropTypes.number,
      netIncome: PropTypes.number,
      equity: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
