const baseUrl = '/companies'; // 'http://localhost:3001/companies'

export const getCompanies = (identifier) =>
  fetch(`${baseUrl}?identifier=${identifier}`).then((response) => {
    if (!response.ok) {
      return response;
    }
    return response.json();
  });

export const getCompany = (symbol) =>
  fetch(`${baseUrl}/${symbol}`).then((response) => response.json());
