/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  chartProps as chart,
  fiscalDateYear,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const netIncomeFail = (netIncome, netIncomeYOY) =>
  netIncome < 0 || netIncomeYOY < 0;

export const netIncomePassStatus = (netIncomeData) => {
  let passStatus = true;
  if (netIncomeData.length) {
    netIncomeData.forEach((item) => {
      if (netIncomeFail(item.netIncome, item.netIncomeYOY)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderNetIncomeItem = (netIncomeData) => {
  const passStatus = netIncomePassStatus(netIncomeData);

  return (
    <NetIncomeItem netIncomeData={netIncomeData} passStatus={passStatus} />
  );
};

export const getNetIncomeData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    netIncome: data.net_income,
    netIncomeYOY: data.net_income_yoy,
    netMargin: data.net_margin,
    revenue: data.revenue,
  }));

function NetIncomeItem({ netIncomeData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [revenueUnit, setRevenueUnit] = React.useState();
  const [netIncomeUnit, setNetIncomeUnit] = React.useState();

  React.useEffect(() => {
    if (netIncomeData.length) {
      setRevenueUnit(
        getUnit(
          Math.max.apply(
            Math,
            netIncomeData.map((o) => o.revenue)
          )
        )
      );
      setNetIncomeUnit(
        getUnit(
          Math.max.apply(
            Math,
            netIncomeData.map((o) => o.netIncome)
          )
        )
      );
    }
  }, [netIncomeData]);

  const passFailClass = (netIncome, netIncomeYOY) =>
    getPassFailClass(netIncomeFail(netIncome, netIncomeYOY));

  // Begin chart data
  const yearLabels = netIncomeData.map((item) =>
    fiscalDateYear(item.fiscalDate)
  );

  const netIncomeDataset = netIncomeData.map((item) =>
    formatValue(item.netIncome, netIncomeUnit)
  );

  const revenueDataset = netIncomeData.map(
    (item) => formatValue(item.revenue, netIncomeUnit) // use same units on chart
  );

  const netMarginDataset = netIncomeData.map((item) => item.netMargin);

  const netIncomeChartLabel = `Net Income (${netIncomeUnit})`;
  const revenueChartLabel = `Revenue (${netIncomeUnit})`;

  const netIncomeChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: netIncomeChartLabel,
        data: netIncomeDataset,
        backgroundColor: chart.color.green,
        borderColor: chart.color.greenBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: revenueChartLabel,
        data: revenueDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
      {
        label: 'Net Margin',
        hidden: true,
        data: netMarginDataset,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
        filter(legendItem) {
          return legendItem.datasetIndex !== 2;
        },
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          const { datasetIndex, index } = tooltipItem;
          const label = data.datasets[datasetIndex].label || '';
          const tooltipNetIncomeData = data.datasets[0].data[index];
          const revenueData = data.datasets[1].data[index];
          switch (label) {
            case netIncomeChartLabel:
              return `Net Income: ${tooltipNetIncomeData} ${netIncomeUnit}`;
            case revenueChartLabel:
              return `Revenue: ${revenueData} ${netIncomeUnit}`;
            default:
              break;
          }
          return null;
        },
        afterLabel(tooltipItem, data) {
          return `Net Margin: ${data.datasets[2].data[tooltipItem.index]}%`;
        },
      },
    },
    scales: chartScales(),
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       // scaleLabel: {
    //       //   display: true,
    //       //   labelString: `Net Income / Revenue (${unit})`
    //       // }
    //     },
    //   ],
    // },
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={netIncomeData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const netMarginTableData = () =>
    netIncomeData.map((item) => (
      <td
        className={passFailClass(
          item.netIncome,
          item.netIncomeYOY ? item.netIncomeYOY : null
        )}
        key={`net-margin-${item.fiscalDate}`}
      >
        {item.netMargin}%
      </td>
    ));

  const revenueTableData = () =>
    netIncomeData.map((item) => (
      <td
        className={passFailClass(
          item.netIncome,
          item.netIncomeYOY ? item.netIncomeYOY : null
        )}
        key={`net-income-revenue-${item.fiscalDate}`}
      >
        {formatValue(item.revenue, revenueUnit)}
      </td>
    ));

  const netIncomeTableData = () =>
    netIncomeData.map((item) => (
      <td
        className={passFailClass(
          item.netIncome,
          item.netIncomeYOY ? item.netIncomeYOY : null
        )}
        key={`net-income-${item.fiscalDate}`}
      >
        {formatValue(item.netIncome, netIncomeUnit)}
      </td>
    ));

  const netIncomeYOYData = () =>
    netIncomeData.map((item) => (
      <td
        className={passFailClass(
          item.netIncome,
          item.netIncomeYOY ? item.netIncomeYOY : null
        )}
        key={`net-income-yoy-${item.fiscalDate}`}
      >
        {item.netIncomeYOY != null ? `${item.netIncomeYOY}%` : ''}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'YOY growth values are positive which means net income has been increasing each year.';
    }
    return 'Net income has decreased. Ideally, it should be increasing every year.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const profitsTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="Net income is known as the bottom line, and it's what's left of the revenue after a
      company pays off all the expenses. Net profit margin is the percentage of revenue remaining."
      importance="Net income indicates whether a company is profitable and it's used to calculate the EPS,
      which can drive the stock price. Net margin measures how good a company is at converting revenue into profits."
      caution="If net margin is increasing significantly, it could be due to a decrease in tax rate
      or from reducing expenses. Constantly cutting costs may not be sustainable in the long term."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Net Income"
      pass={passStatus}
      icon={faFunnelDollar}
      tip={profitsTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={netIncomeChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th className="w-1/5" />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName="Net Margin" />
        {netMarginTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Revenue (${revenueUnit})`} />
        {revenueTableData()}
      </tr>
      <tr>
        <RowHeader itemName={`Net Income (${netIncomeUnit})`} />
        {netIncomeTableData()}
      </tr>
      <tr>
        <RowHeader itemName="YOY Growth" />
        {netIncomeYOYData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

NetIncomeItem.propTypes = {
  netIncomeData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      netIncome: PropTypes.number,
      netIncomeYOY: PropTypes.number,
      netMargin: PropTypes.number,
      revenue: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
