import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function PageMenu({ pages, onChange }) {
  const [selected, setSelected] = useState(pages.OVERVIEW);

  const updateSelected = (page) => {
    setSelected(page);
    onChange(page);
  };

  const textStateClass = (page) => {
    if (page === selected) {
      return 'text-palette-green-med border-b-2 font-bold';
    }
    return 'text-gray-500';
  };

  const pageItems = Object.entries(pages).map(([key, value]) => (
    <div
      key={`page-${key}`}
      onClick={() => updateSelected(value)}
      onKeyPress={() => updateSelected(value)}
      className={`mx-4 cursor-pointer ${textStateClass(value)}`}
      role="button"
      tabIndex={0}
    >
      {value}
    </div>
  ));

  return (
    <div className="flex flex-row flex-wrap flex-grow font-semibold text-xl mt-4">
      {pageItems}
    </div>
  );
}

PageMenu.propTypes = {
  pages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
