/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prefer-spread */
import React from 'react';
import PropTypes from 'prop-types';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { YearsTableHeader, RowHeader } from './components/TableComponents';
import { ReportItem, ItemTitle, ItemTip } from './components/ReportComponents';
import {
  getPassFailClass,
  fiscalDateYear,
  chartProps as chart,
  getUnit,
  formatValue,
  chartScales,
} from './utils';

const freeCashFlowFail = (freeCashFlow) => freeCashFlow < 0;

export const freeCashFlowPassStatus = (freeCashFlowData) => {
  let passStatus = true;
  if (freeCashFlowData.length) {
    freeCashFlowData.forEach((item) => {
      if (freeCashFlowFail(item.freeCashFlow)) {
        passStatus = false;
      }
    });
  }
  return passStatus;
};

export const renderFreeCashFlowItem = (freeCashFlowData) => {
  const passStatus = freeCashFlowPassStatus(freeCashFlowData);

  return (
    <FreeCashFlowItem
      freeCashFlowData={freeCashFlowData}
      passStatus={passStatus}
    />
  );
};

export const getFreeCashFlowData = (reportData) =>
  reportData.map((data) => ({
    fiscalDate: data.fiscal_date,
    freeCashFlow: data.free_cash_flow,
  }));

function FreeCashFlowItem({ freeCashFlowData, passStatus }) {
  const [tipDisplay, setTipDisplay] = React.useState(false);
  const [unit, setUnit] = React.useState();

  React.useEffect(() => {
    if (freeCashFlowData.length) {
      setUnit(
        getUnit(
          Math.max.apply(
            Math,
            freeCashFlowData.map((o) => o.freeCashFlow)
          )
        )
      );
    }
  }, [freeCashFlowData]);

  const passFailClass = (freeCashFlow) =>
    getPassFailClass(freeCashFlowFail(freeCashFlow));

  // Begin chart data
  const yearLabels = freeCashFlowData.map((item) =>
    fiscalDateYear(item.fiscalDate)
  );

  const freeCashFlowDataset = freeCashFlowData.map((item) =>
    formatValue(item.freeCashFlow, unit)
  );

  const freeCashFlowChartData = () => ({
    labels: yearLabels,
    datasets: [
      {
        label: `Free Cash Flow (${unit})`,
        data: freeCashFlowDataset,
        backgroundColor: chart.color.blue,
        borderColor: chart.color.blueBorder,
        borderWidth: chart.bar.borderWidth,
        barPercentage: chart.bar.percentage,
      },
    ],
  });

  const options = {
    legend: {
      display: chart.legend.display,
      position: chart.legend.position,
      labels: {
        boxWidth: chart.legend.boxWidth,
        fontSize: chart.legend.fontSize,
        padding: chart.legend.padding,
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
        label(tooltipItem, data) {
          return `Free Cash Flow: ${
            data.datasets[0].data[tooltipItem.index]
          } ${unit}`;
        },
      },
    },
    scales: chartScales(),
  };
  // End chart data

  // Begin table data
  const displayYears = () => (
    <YearsTableHeader
      years={freeCashFlowData.map((item) => fiscalDateYear(item.fiscalDate))}
    />
  );

  const freeCashFlowTableData = () =>
    freeCashFlowData.map((item) => (
      <td
        className={passFailClass(item.freeCashFlow)}
        key={`free-cash-flow-${item.fiscalDate}`}
      >
        {formatValue(item.freeCashFlow, unit)}
      </td>
    ));
  // End table data

  const guidance = (pass) => {
    if (pass) {
      return 'Free cash flow is consistently positive, which means the company can reinvest back into the company.';
    }
    return 'Negative free cash flow is not necessarily bad, but ideally, it should be consistently positive, which means the company can reinvest back into the company.';
  };

  const closeTip = () => {
    setTipDisplay(false);
  };

  const freeCashFlowTip = (
    <ItemTip
      guidance={guidance(passStatus)}
      definition="This is cash that a company generates after paying expenses."
      importance="It can allow a company to develop new products, make
    acquisitions, pay dividends, or reduce debt. Growing free cash flows frequently
    leads to increased earnings."
      caution="It should not be continuously decreasing."
      onClose={closeTip}
    />
  );

  const itemTitle = (
    <ItemTitle
      title="Free Cash Flow"
      icon={faMoneyBillWave}
      pass={passStatus}
      tip={freeCashFlowTip}
      setDisplay={setTipDisplay}
      tipDisplay={tipDisplay}
    />
  );

  const itemChart = <Bar data={freeCashFlowChartData} options={options} />;

  const tableBody = (
    <tbody>
      <tr>
        <th />
        {displayYears()}
      </tr>
      <tr>
        <RowHeader itemName={`Free Cash Flow (${unit})`} />
        {freeCashFlowTableData()}
      </tr>
    </tbody>
  );

  return (
    <ReportItem
      itemTitle={itemTitle}
      itemChart={itemChart}
      tableBody={tableBody}
    />
  );
}

FreeCashFlowItem.propTypes = {
  freeCashFlowData: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalDate: PropTypes.string,
      freeCashFlow: PropTypes.number,
    })
  ).isRequired,
  passStatus: PropTypes.bool.isRequired,
};
