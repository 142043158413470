import errorMessages from './constants';

const noDataError =
  "We don't have data for this company, but we'll work on it. We currently support over 2500 currently active U.S. exchange-traded stocks, focusing on companies with higher market caps and trading volumes.";
const genericError =
  "Something went wrong and we couldn't process your request.";

const getErrorMessage = (error) => {
  switch (error) {
    case errorMessages.noData:
    case errorMessages.notFound:
      return noDataError;
    default:
      return genericError;
  }
};

export default getErrorMessage;
